import { useEffect, useState } from 'react';
import Axios from 'axios';
import * as React from 'react';
import 'react-dropdown/style.css';
import './h2app.css';

function App() {

// Initializing all the state variables
const [info, setInfo] = useState([]);
const [input, setInput] = useState(0);
const [from, setFrom] = useState("usd");
const [to, setTo] = useState("inr");
const [options, setOptions] = useState([]);
const [output, setOutput] = useState("");
const [breakdown, setBreakdown] = useState("");

// Calling the api whenever the dependency changes
useEffect(() => {
	Axios.get(
`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`)
.then((res) => {
	setInfo(res.data[from]);
	})
}, [from]);

useEffect(() => {
	convert_to_decimal()
}, [input]);

// Calling the convert function whenever
// a user switches the currency
useEffect(() => {
	setOptions(Object.keys(info));
	convert();
}, [info])
	
// Function to convert the currency
function convert() {
	var rate = info[to];
	setOutput(input * rate);
}

// Function to convert to decimal
function convert_to_decimal() {
    console.log(input);
    console.log(input.type);
    console.log(input.length)
    let len = input.length;
    let result = "";
    let deci = 0;
    if (input === 0) {
        console.log("Input string not found")
        result = "Invalid string, empty"
    } else if (len % 2 === 1) {
        console.log("Not valid");
        result = "Invalid, string should be even numbered";
    } else if (len === 0) {
        console.log("Input string not found")
        result = "Invalid string, empty"
    } else {
        for (let i = 0; i < input.length; i=i+2) {
            let hex, deci = 0;
            let ch = "";
            deci = parseInt(input[i], 16) * 16 + parseInt(input[i+1], 16);
            console.log(deci)
            ch = String.fromCharCode(deci)
            console.log(ch);
            result += ch;
            
          }
    }
    console.log()
	get_breakdown(result)
	setOutput(result);
}

function get_breakdown(result) {
    if (result.length >= 14) {
        setBreakdown(" ")
    } else {
        console.log(result)
        let br = []
        let bd = ""
        bd = "Manufacturer: " + result.slice(1,3)
        br.push(bd)
        br.push(<br/>)
        bd = "Identifier: " + result.slice(4,14 )
        br.push(bd)
        br.push(<br/>)
        // bd = "Time: " + result.slice(18,20) + ":" + result.slice(20,22) + ":" + result.slice(22,24)
        // br.push(bd)
        // br.push(<br/>)
        setBreakdown(br)
    }
}


// Function to switch between two currency
function flip() {
	var temp = from;
	setFrom(to);
	setTo(temp);
}

return (
	<div className="App">
	<div className="h1">
		<h1>H02 Protocol Decoder</h1>
	</div>
	<div className="container">
		<div className="left">
		<h3>H02 Packet (Hex)</h3>
        <textarea type="text"
			placeholder="2a"
			onChange={(e) => setInput(e.target.value)} />
		</div>
	</div>
    <p></p>
    <div className='buttond'>
        <button onClick={()=>{convert_to_decimal()}}>Convert</button>
    </div>
	<div className="container">
        <div className="left">
		<h3>Converted Decimal</h3>
        <p>{output}</p>
	</div>
    </div>
        <div className="left">
		<h3>Breakdown</h3>
        <p>{breakdown}</p>
        </div>
	</div>
);
}

export default App;
